.sidebar.sidebar-four {
    border: 0;
    box-shadow: 0px 4px 14px rgba(198, 198, 198, 0.25);
    .sidebar-menu {
        @extend %display-flex;
        border-radius: 0 20px 20px 0;
        .ui-aside {
            float: $__left;
            width: $__full__width;
            max-width: 66px;
            margin-left: 0px;
            color: rgba(255, 255, 255, 0.5);
            @include transition( all .299s);
            @include margin-padding(0, 0);
            box-shadow: 0px 0 14px rgba(198, 198, 198, 0.25);
            min-height: $__full_height_window;
            .nav-tabs {
                @include margin-padding(null, 0 19px);
                border: 0;
            .nav-item {
                .nav-link {
                    @include margin-padding(null, 20px 5px);
                    border-bottom: 1px solid $__grey;
                    border-width: 0 0 1px;
                    &:hover {
                        border-width: 0 0 1px;
                        border-bottom: 1px solid $__grey;
                    }
                    &:active, &.active {
                        img {
                            filter: brightness(0) saturate(100%) invert(75%) sepia(10%) saturate(5676%) hue-rotate(326deg) brightness(105%) contrast(112%);
                        }
                        &::after {
                            content: $__none;
                        }
                        border-color: $__white $__white $__grey;
                    }
                    &::before {
                        content: $__none;
                    }
                }
                &:last-child {
                    .nav-link {
                    border-bottom: 0;
                    }
                }
            }
        }
    }
}
    .tab-content-four {
        padding-top: 20px;
        margin-left: 10px;
        width: $__full__width;
    }
    ul ul {
        display: $__none;
    }
    .sidebar-menu {
        @include margin-padding(null, 0 20px 0 0);
        ul li {
            a {
                @include margin-padding(null, 10px 15px 10px 25px);
                @extend %display-flex;
                @extend %align-items-center;
                position: $__relative;
                color: $__sandstone;
                svg{
                    width: 18px;
                    color: $__sandstone;
                }
                img{
                    width:18px;
                    color: $__sandstone;
                }
                &:hover{
                    color: $__primarycolor;
                    img {
                        filter: brightness(0) saturate(100%) invert(75%) sepia(10%) saturate(5676%) hue-rotate(326deg) brightness(105%) contrast(112%);
                    }
                    span{
                        color: $__primarycolor;
                    }
                    svg{
                        color: $__white;
                    }
                }
                &::before {
                    content: "";
                    @include position($__absolute, 50%, null, null, 10px);
                    transform: translateY(-50%);
                    background: $__ash;
                    width: 5px;
                    height: 5px;
                }
                &.active{
                    color: $__primarycolor;
                    svg{
                        color: $__white;
                    }
                    img {
                        filter: brightness(0) saturate(100%) invert(75%) sepia(10%) saturate(5676%) hue-rotate(326deg) brightness(105%) contrast(112%);
                    }
                    span{
                        color: $__white;
                    }
                }
                span{
                    margin-left: 0;
                    font-size: $__font__size__15;
                    font-weight: $__medium;
                    color: $__sandstone;
                }
                .menu-arrow {
                    display: $__inline__block;
                    font-family: "Font Awesome 5 Free";
                    text-rendering: $__auto;
                    line-height: 40px;
                    font-size: $__font__size__18;
                    line-height: 18px;
                    @include position($__absolute,12px,15px,null,null);
                    @include transform(translate(0, 0));
                    @include transition(all 0.2s ease);
                }
                &.subdrop{
                    .menu-arrow {
                        @include transform(rotate(90deg));
                    }
                }
            }
            ul {
                li {
                    a {
                        @include margin-padding(null, 10px 15px 10px 25px);
                        left: 14px;
                    }
                }
            }
        }
    }
}
.header-four {
    .header-left {
        border-right: 0 !important;
    }
}
.page-wrapper-four {
    margin-left: 250px;
}

@include respond-below(custom992) {
    .page-wrapper-four {
        margin-left: 0;

    } 
}
